import { Props } from "./RefundTpp";
import { extendDeadline } from "../../../api";
import { ErrorMessage, Input, SVG, useErrors } from "react-components";
import { FC, useState } from "react";
import {
  allFinalPaymentFees,
  CENTRAL_TZ,
  departureEODinCT,
  depositFee,
  finalPaymentDueBy,
  formatCents,
  HH_mm_ss,
  holdExpiredAt,
  isFeeOwed,
} from "data-model";
import { DateTime } from "luxon";

const ExtendDeadline: FC<Props> = ({ booking, onClose, setBooking }) => {
  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const now = DateTime.now();

  const originalDepositBy = DateTime.fromISO(booking.depositDueBy, {
    zone: CENTRAL_TZ,
  });
  const [depositDate, setDepositDate] = useState(originalDepositBy.toISODate());
  const [depositTime, setDepositTime] = useState(
    originalDepositBy.toFormat(HH_mm_ss)
  );
  const depositDateTime =
    depositDate && depositTime
      ? DateTime.fromISO(`${depositDate}T${depositTime}`, { zone: CENTRAL_TZ })
      : null;

  const originalFinalBy = DateTime.fromISO(booking.finalDueBy, {
    zone: CENTRAL_TZ,
  });
  const [finalDate, setFinalDate] = useState(originalFinalBy.toISODate());
  const [finalTime, setFinalTime] = useState(
    originalFinalBy.toFormat(HH_mm_ss)
  );
  const finalDateTime =
    finalDate && finalTime
      ? DateTime.fromISO(`${finalDate}T${finalTime}`, { zone: CENTRAL_TZ })
      : null;

  const [minDepositBy, minFinalBy] = policyDeadlines(
    booking.departedAt,
    booking.createdAt
  );
  const maxBy = departureEODinCT(booking.departedAt).minus({ days: 7 });

  const totalDepositDue = booking.guests.reduce((acc, g) => {
    const deposit = depositFee(g);
    if (isFeeOwed(deposit)) {
      acc += deposit.total;
    }
    return acc;
  }, 0);
  const totalFinalDue = booking.guests.reduce((acc, g) => {
    allFinalPaymentFees(g).forEach((f) => {
      if (isFeeOwed(f)) {
        acc += f.total;
      }
    });
    return acc;
  }, 0);

  const handleApply = async () => {
    setIsLoading(true);
    catchErrors(
      async () => {
        const { expiredAt, depositDueBy, finalDueBy } = await extendDeadline(
          booking.id,
          {
            depositDueBy: depositDateTime!.toUTC().toISO(),
            finalDueBy: finalDateTime!.toUTC().toISO(),
          }
        );

        setBooking((b) => ({
          ...b,
          expiredAt,
          depositDueBy,
          finalDueBy,
        }));

        onClose();
      },
      () => setIsLoading(false)
    );
  };

  return (
    <>
      <h1 id="modal-title" className="is-flex margin-bottom-5">
        Extend Hold or Final Payment
        {isLoading && (
          <SVG
            className="margin-left-2"
            path="/site/icon/spinner"
            alt="Loading"
            height={18}
          />
        )}
      </h1>
      <div id="modal-description">
        <p>Extend hold (deposit) &ndash; {formatCents(totalDepositDue)} due:</p>

        <div className="is-flex is-align-items-center">
          <Input
            type="date"
            value={depositDate}
            onChange={(e) => setDepositDate(e.currentTarget.value)}
            disabled={totalDepositDue === 0}
          />

          <Input
            type="time"
            className="margin-x-2"
            value={depositTime}
            onChange={(e) => setDepositTime(e.currentTarget.value)}
            disabled={totalDepositDue === 0}
          />

          <span>{tzName(depositDateTime, originalDepositBy)}</span>
        </div>

        <hr className="margin-y-3" />

        <p>Extend final payment &ndash; {formatCents(totalFinalDue)} due:</p>

        <div className="is-flex is-align-items-center margin-bottom-5">
          <Input
            type="date"
            value={finalDate}
            onChange={(e) => setFinalDate(e.currentTarget.value)}
            disabled={totalFinalDue === 0}
          />

          <Input
            type="time"
            className="margin-x-2"
            value={finalTime}
            onChange={(e) => setFinalTime(e.currentTarget.value)}
            disabled={totalFinalDue === 0}
          />

          <span>{tzName(finalDateTime, originalFinalBy)}</span>
        </div>

        <ErrorMessage
          errors={errors}
          className="margin-top-2 margin-bottom-2"
        />

        <div className="is-flex margin-top-5">
          <button
            className="button is-light-blue is-outlined margin-right-2"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            className="button is-light-blue is-flex-1"
            onClick={handleApply}
            disabled={
              isLoading ||
              !depositDateTime ||
              !finalDateTime ||
              depositDateTime > finalDateTime ||
              // Cannot be in the past (except if already paid, so that
              // if an older booking has an incorrect deposit_due_by,
              // final_due_by can still be edited).
              (totalDepositDue !== 0 && depositDateTime < now) ||
              finalDateTime < now ||
              // Cannot be sooner than policy dates
              (totalDepositDue !== 0 && depositDateTime < minDepositBy) ||
              finalDateTime < minFinalBy ||
              // Cannot be extended past 7d before departure
              (totalDepositDue !== 0 && depositDateTime > maxBy) ||
              finalDateTime > maxBy ||
              totalFinalDue === 0 // fully paid
            }
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
};

export { ExtendDeadline };

const policyDeadlines = (departedAt: string, createdAt: string) => {
  try {
    // Throws when created_at > departed_at.
    return [
      holdExpiredAt(departedAt, createdAt),
      finalPaymentDueBy(departedAt, createdAt),
    ];
  } catch (e) {
    const createdDateTime = DateTime.fromISO(createdAt, { zone: CENTRAL_TZ });
    const after8h = createdDateTime.plus({ hours: 8 });
    return [after8h, after8h];
  }
};

const tzName = (date: DateTime | null, original: DateTime) => {
  if (date) {
    // While the year is edited, this can return non-CT values.
    const tz = date.toFormat("ZZZZ");
    if (tz === "CST" || tz === "CDT") return tz;
  }
  return original.toFormat("ZZZZ");
};
